export default function VerificationDropdownIcon({
  fill = "#E8E5FF",
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fill}
      {...props}
    >
      <path
        d="M9.7457 19.5703H9.90061C9.97807 19.5703 17.4914 17.7888 17.4914 9.50091V4.85349C17.4914 4.54366 17.3365 4.23383 17.0267 4.15637L10.0555 1.05809C9.82316 0.980636 9.59079 0.980636 9.43587 1.05809L2.46474 4.15637C2.15491 4.23383 2 4.54366 2 4.85349V9.50091C2 17.7888 9.51333 19.5703 9.59079 19.5703H9.7457ZM8.19656 13.3738C7.96419 13.3738 7.80927 13.2963 7.65436 13.1414L5.33065 10.8177C5.02082 10.5078 5.02082 10.0431 5.33065 9.73328C5.64048 9.42345 6.10522 9.42345 6.41505 9.73328L8.19656 11.5148L13.0763 6.635C13.3862 6.32517 13.8509 6.32517 14.1607 6.635C14.4706 6.94482 14.4706 7.40957 14.1607 7.71939L8.73876 13.1414C8.58384 13.2963 8.42893 13.3738 8.19656 13.3738Z"
        fill={fill}
      />
    </svg>
  );
}
