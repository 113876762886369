export default function BetsDropdownIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M14.5 8.50016C14.4998 9.11078 14.3398 9.71072 14.036 10.2404C13.7322 10.7701 13.2951 11.211 12.7681 11.5195C12.2412 11.828 11.6427 11.9932 11.0321 11.9988C10.4215 12.0044 9.82004 11.8502 9.2875 11.5514L9.97875 13.8564C10.0012 13.9311 10.0058 14.0099 9.99232 14.0867C9.97883 14.1634 9.94758 14.236 9.90107 14.2985C9.85456 14.3611 9.79407 14.4119 9.72443 14.4469C9.6548 14.4819 9.57794 14.5001 9.5 14.5002H6.5C6.42206 14.5001 6.3452 14.4819 6.27557 14.4469C6.20593 14.4119 6.14544 14.3611 6.09893 14.2985C6.05242 14.236 6.02117 14.1634 6.00768 14.0867C5.9942 14.0099 5.99884 13.9311 6.02125 13.8564L6.7125 11.5514C6.17996 11.8502 5.57853 12.0044 4.96793 11.9988C4.35734 11.9932 3.75883 11.828 3.23186 11.5195C2.70489 11.211 2.26779 10.7701 1.96397 10.2404C1.66016 9.71072 1.50021 9.11078 1.5 8.50016C1.5 6.50016 2.60313 4.57266 4.6875 2.92079C5.64446 2.1661 6.69194 1.53391 7.80562 1.03891C7.86655 1.01323 7.93201 1 7.99813 1C8.06424 1 8.1297 1.01323 8.19063 1.03891C9.30562 1.53364 10.3544 2.16583 11.3125 2.92079C13.3969 4.57266 14.5 6.50016 14.5 8.50016Z"
        fill="#E8E5FF"
      />
    </svg>
  );
}
