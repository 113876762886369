import React from "react";
import st from "../../styles/components/RakebackBoost.module.scss";
import RakebackProgress from "./RakebackProgress";
import { useDict } from "@hooks/useLanguage";

const countdown = (leftTime) => {
  const { days, hours, minutes, seconds } = leftTime;

  const totalHours = days * 24 + hours;

  const formattedHours = String(totalHours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export default function RakebackBoost({
  progress,
  leftTime,
  rakebackRate,
  boostType,
  mobile = false,
  size,
  strokeWidth = 2.5,
  noHover = false,
  insideDropdown = false,
}) {
  const Dict = useDict(["Rewards", "common"]);

  return (
    <div
      className={`${st["container"]} ${mobile && st["mobile"]} ${
        noHover ? st["noHover"] : ""
      }`}
    >
      <div
        className={`${st["tooltip"]} ${
          insideDropdown ? st["tooltip-inside-dropdown"] : ""
        }`}
      >
        <span className={st["tooltip-text"]}>
          <Dict
            name="rakeboost_tooltip_label"
            className={st["rake-label"]}
            as="span"
          />
          <Dict
            name="rakeboost_tooltip"
            as="span"
            replacements={{ rate: rakebackRate }}
          />
          <Dict
            name={`BoostType.${boostType}`}
            as="span"
            className={st["boost-type"]}
          />
        </span>
      </div>
      <div className={st["icon"]}>
        <RakebackProgress
          size={size}
          progress={progress}
          strokeWidth={mobile ? 1.5 : strokeWidth}
        />
      </div>
      <div className={st["info"]}>
        <div className={st["label"]}>{rakebackRate}% Boost</div>
        <p className={st["time"]}>{countdown(leftTime)}</p>
      </div>
    </div>
  );
}
