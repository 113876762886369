export default function ProfileDropdownIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M14.4326 13.7497C14.3887 13.8258 14.3256 13.8889 14.2496 13.9328C14.1735 13.9767 14.0873 13.9998 13.9995 13.9997H1.99949C1.91176 13.9997 1.82561 13.9765 1.74967 13.9326C1.67373 13.8886 1.61068 13.8255 1.56686 13.7495C1.52304 13.6735 1.49998 13.5873 1.5 13.4996C1.50002 13.4119 1.52312 13.3257 1.56699 13.2497C2.51886 11.6041 3.98574 10.4241 5.69761 9.86475C4.85084 9.36066 4.19295 8.59255 3.82498 7.67837C3.457 6.76419 3.39929 5.7545 3.6607 4.80435C3.92211 3.85419 4.48819 3.01612 5.272 2.41883C6.05582 1.82153 7.01403 1.49805 7.99949 1.49805C8.98494 1.49805 9.94316 1.82153 10.727 2.41883C11.5108 3.01612 12.0769 3.85419 12.3383 4.80435C12.5997 5.7545 12.542 6.76419 12.174 7.67837C11.806 8.59255 11.1481 9.36066 10.3014 9.86475C12.0132 10.4241 13.4801 11.6041 14.432 13.2497C14.476 13.3257 14.4992 13.4119 14.4993 13.4997C14.4994 13.5874 14.4764 13.6737 14.4326 13.7497Z"
        fill="#E8E5FF"
      />
    </svg>
  );
}
