export default function LogoutDropdownIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M7.25 13.5C7.25 13.6989 7.17098 13.8897 7.03033 14.0303C6.88968 14.171 6.69891 14.25 6.5 14.25H3C2.66848 14.25 2.35054 14.1183 2.11612 13.8839C1.8817 13.6495 1.75 13.3315 1.75 13V3C1.75 2.66848 1.8817 2.35054 2.11612 2.11612C2.35054 1.8817 2.66848 1.75 3 1.75H6.5C6.69891 1.75 6.88968 1.82902 7.03033 1.96967C7.17098 2.11032 7.25 2.30109 7.25 2.5C7.25 2.69891 7.17098 2.88968 7.03033 3.03033C6.88968 3.17098 6.69891 3.25 6.5 3.25H3.25V12.75H6.5C6.69891 12.75 6.88968 12.829 7.03033 12.9697C7.17098 13.1103 7.25 13.3011 7.25 13.5ZM14.0306 7.46938L11.5306 4.96938C11.3897 4.82848 11.1986 4.74932 10.9994 4.74932C10.8001 4.74932 10.609 4.82848 10.4681 4.96938C10.3272 5.11027 10.2481 5.30137 10.2481 5.50063C10.2481 5.69988 10.3272 5.89098 10.4681 6.03188L11.6875 7.25H6.5C6.30109 7.25 6.11032 7.32902 5.96967 7.46967C5.82902 7.61032 5.75 7.80109 5.75 8C5.75 8.19891 5.82902 8.38968 5.96967 8.53033C6.11032 8.67098 6.30109 8.75 6.5 8.75H11.6875L10.4675 9.96937C10.3266 10.1103 10.2474 10.3014 10.2474 10.5006C10.2474 10.6999 10.3266 10.891 10.4675 11.0319C10.6084 11.1728 10.7995 11.2519 10.9987 11.2519C11.198 11.2519 11.3891 11.1728 11.53 11.0319L14.03 8.53187C14.1 8.46224 14.1555 8.37948 14.1934 8.28833C14.2313 8.19719 14.2508 8.09946 14.2509 8.00075C14.251 7.90204 14.2315 7.80429 14.1937 7.71311C14.1559 7.62192 14.1005 7.53909 14.0306 7.46938Z"
        fill="#E8E5FF"
      />
    </svg>
  );
}
