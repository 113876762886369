import { removeDateOffset } from "@lib";
import { useAtomValue } from "jotai";
import { requestTimeAtom } from "@store/global";
import { useCallback, useEffect, useState } from "react";

/**
 * Custom hook that returns the current time. Use with caution, as it can cause performance issues.
 */
const useNow = () => {
  const reqTime = useAtomValue(requestTimeAtom);
  const [now, setNow] = useState(removeDateOffset(reqTime).valueOf());

  const update = useCallback(
    () => setNow(removeDateOffset(Date.now()).getTime()),
    []
  );

  useEffect(() => {
    const id = setInterval(update, 1000);
    return () => clearInterval(id);
  }, [update]);

  return now;
};

export default useNow;
