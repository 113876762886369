import { useCallback } from "react";
import { formatValueDisplay } from "../lib/tools/convert";
import { useLocale } from "../lib/tools/helpers";
import { sBig } from "@lib/tools/numberHelpers";
import Big from "big.js";
import useWallet from "./useWallet";
/**
 * Custom hook for handling currency conversion and formatting.
 * @returns The function for converting and formatting currency amounts.
 */
export default function useConvert() {
  const { activeRate } = useWallet();
  const locale = useLocale();

  // /**
  //  * Converts and formats a currency amount.
  //  * @param {number} amount - The amount to convert and format.
  //  * @param {boolean} [symbol=true] - Whether to include the currency symbol in the formatted result. Default is true.
  //  * @returns {string} The converted and formatted currency amount.
  //  */
  // const getAmount = useCallback(
  //   (amount, symbol = true, reverse = false, raw = false) => {
  //     if (reverse) {
  //       return convertToBaseValue(amount, activeRate);
  //     }

  //     if (symbol || raw) {
  //       //returns formatted string
  //       return formatValueAmount(amount, activeRate, raw, symbol, locale);
  //     }
  //     //Returns raw number
  //     return rateConvert(amount, activeRate);
  //   },
  //   [activeRate, locale]
  // );
  /**
   * Converts and formats a currency amount back to the base amount in USD.
   * @param {number} amount - The amount to convert and format.
   * @returns {number} The converted and formatted base amount.
   */
  const getBaseAmount = useCallback(
    (amount) => {
      let newAmount = sBig(Number(amount)).div(sBig(Number(activeRate?.rate)));
      return Number(newAmount.toFixed(2));
    },
    [activeRate]
  );

  /**
   * Returns the formatted amount based on the provided parameters.
   *
   * @param {number} amount - The amount to be formatted.
   * @param {object} options - The formatting options.
   * @param {boolean} options.symbol - Whether to include the currency symbol. Default is false.
   * @param {boolean} options.raw - Whether to return the raw formatted value. Default is false.
   * @returns {string} The formatted amount.
   */
  const getFormattedAmount = useCallback(
    (amount, { symbol = false, raw = false } = {}) => {
      return formatValueDisplay(amount, {
        rate: activeRate,
        raw: raw,
        symbol: symbol,
        locale: locale,
      });
    },

    [activeRate, locale]
  );

  /**
   * Converts the given amount using the active rate.
   *
   * @param {number} amount - The amount to be converted.
   * @param {number} [cutOfDecimals=2] - The number of decimal places to truncate to. Default is 2.
   * @param {object} [round={ decimal: 2, direction: false }] - Rounding options.
   * @param {number} [round.decimal=2] - Number of decimals to round to.
   * @param {string} [round.direction=false] - Direction to round ('up' or 'down').
   * @returns {number} - The converted amount.
   */
  const getConvertedAmount = useCallback(
    (amount, { cutOffDecimals = 2, round = {}, customRate = false } = {}) => {
      const { decimal = 2, direction = false } = round;

      let currencyRate = activeRate.rate;
      if (customRate) {
        currencyRate = customRate;
      }

      let newAmount = sBig(currencyRate).times(sBig(amount));
      if (direction) {
        if (direction === "up") {
          newAmount = newAmount.round(decimal, Big.roundUp);
        } else if (direction === "down") {
          newAmount = newAmount.round(decimal, Big.roundDown);
        } else {
          newAmount = newAmount.round(decimal, Big[direction]);
        }
      }
      return Number(newAmount.toFixed(cutOffDecimals, 0));
    },
    [activeRate]
  );

  return { getBaseAmount, getFormattedAmount, getConvertedAmount };
}
