import AffiliatesDropdownIcon from "./AffiliatesDropdownIcon";
import BetsDropdownIcon from "./BetsDropdownIcon";
import LogoutDropdownIcon from "./LogoutDropdownIcon";
import ProfileDropdownIcon from "./ProfileDropdownIcon";
import RewardsDropdownIcon from "./RewardsDropdownIcon";
import SettingsDropdownIcon from "./SettingsDropdownIcon";
import TransactionsDropdownIcon from "./TransactionsDropdownIcon";
import VaultDropdownIcon from "./VaultDropdownIcon";
import VerificationDropdownIcon from "./VerificationDropdownIcon";

export const DropdownIcons = {
  RewardsDropdownIcon,
  LogoutDropdownIcon,
  SettingsDropdownIcon,
  VaultDropdownIcon,
  VerificationDropdownIcon,
  ProfileDropdownIcon,
  AffiliatesDropdownIcon,
  BetsDropdownIcon,
  TransactionsDropdownIcon,
};
