import st from "../styles/components/Header.module.scss";
import StorageService from "../lib/services/Storage.Service";
import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Button from "../items/Button";
import useLanguage from "../hooks/useLanguage";
import API from "../lib/api/api";
import usePopup from "../hooks/usePopup";
import useMobile from "../hooks/useMobile";
import { useUserDetails, useUserSession } from "../hooks/useUserSession";
import { CasinoIconNew } from "@assets/icons/general/CasinoIcon";
import ChatIco from "@assets/svgs/ChatIco";
import SearchIcon from "@assets/svgs/SearchIcon";
import useNow from "@hooks/useNow";
import useSocket from "@hooks/useSocket";
import { timeLeft } from "@lib/timer";
import useWallet from "@hooks/useWallet";
import { useRecoilState } from "recoil";
import userRakebackBoosted from "@recoil/rakebackBoosted/atom";
import { NewLogoIcon, XmasLogo } from "@assets/icons/general/NewLogo";
import { NODE_API2 } from "../lib/api/nodeApi";
import { differenceInMilliseconds } from "date-fns";
import { RewardsDropdown } from "@components/header/rewards-dropdown";
import ProfileDropdown from "./header/profile-dropdown";
import dynamic from "next/dynamic";
import Link from "next/link";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { BalanceDropdown } from "./header/balance-dropdown";
import { useAtomValue } from "jotai";
import { activeRewardsDropdownAtom } from "@store/rewards-dropdown-atom";
import { getTimeRemaining } from "@lib";

const NotificationDropdown = dynamic(() => import("./NotificationDropdown"));
const MobileNav = dynamic(() => import("./MobileNav"));
const RakebackBoost = dynamic(() => import("./RakebackBoost"));

export default function Header(props) {
  const socket = useSocket(process.env.NEXT_PUBLIC_ALERTS_SOCKET_URL);
  const activeRewardsDropdown = useAtomValue(activeRewardsDropdownAtom);

  const {
    setChat,
    chat,
    setMiniSide = () => {},
    setMenuToggle = () => {},
    onUserLoggedChange = () => {},
  } = props;

  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const L = useLanguage(["common", "meta", "auth"]);
  const {
    signOut: logout,
    userData,
    hasUserData,
    mutate,
    addTokenHeader,
  } = useUserSession();
  const { balanceMutate } = useWallet();

  const setMessage = usePopup();
  const isMobile = useMobile({
    breakPointOne: 1100,
    breakPointTwo: 525,
    breakPointThree: 1000,
  });

  const openedModal = useRef(false);
  const affiliateRedeemed = useRef(false);
  const [affiliateApproved, setAffiliateApproved] = useState(null);
  const refSendPromo = useRef(false);
  const refPromoSent = useRef(false);

  const removeQueryParam = useCallback(
    (param) => {
      const params = new URLSearchParams(searchParams);
      params.delete(param);
      router.replace(`${pathname}?${params.toString()}`);
    },
    [pathname, router, searchParams]
  );

  const balance_update = useCallback(() => {
    return Promise.all([balanceMutate(), mutate()]);
  }, [balanceMutate, mutate]);

  useEffect(() => {
    socket?.on("balance_update", balance_update);

    return () => {
      socket?.off("balance_update", balance_update);
    };
  }, [socket, balance_update]);

  const [menuState, setMenuState] = useState("games");
  const now = useNow();

  const handleChat = () => {
    setChat(!chat);
  };

  //Logs the user out if delete is present
  useEffect(() => {
    if (userData?.deleted) {
      logout();
    }
  }, [logout, userData]);

  useEffect(() => {
    onUserLoggedChange(hasUserData);
  }, [hasUserData, onUserLoggedChange]);

  useEffect(() => {
    setTimeout(() => {
      if (!userData && affiliateApproved) {
        router.push(`/?modal=auth&tab=register`);
      }
      setAffiliateApproved(null);
    }, 500);
  }, [userData, affiliateApproved, router]);

  const checkReferralCode = useCallback((code) => {
    const data = {
      affiliate_code: code,
    };

    API.post("affiliate/validate", data)
      .then((res) => {
        if (res.status === 202) {
          StorageService.setReferralCode(code);
          setAffiliateApproved(true);
        }
      })
      .catch(() => {});
  }, []);

  //Collect the referral code from the url
  useEffect(() => {
    //No # and it detects the full text
    if (searchParams.has("r")) {
      openedModal.current = true;
      const code = searchParams.get("r");
      removeQueryParam("r");
      checkReferralCode(code);
    }
  }, [checkReferralCode, removeQueryParam, searchParams]);

  useEffect(() => {
    //No # and it detects the full text
    if (searchParams.has("promo")) {
      openedModal.current = true;
      StorageService.setLocalStorageValue("promo", searchParams.get("promo"));
      removeQueryParam("promo");
    } else {
      const fragmentIdentifier = window.location.hash;

      // // If no referral code was found in the query string, check the fragment identifier
      if (fragmentIdentifier) {
        refSendPromo.current = true;
        StorageService.setLocalStorageValue("promo", fragmentIdentifier);
        removeQueryParam("promo");
      }
    }
  }, [removeQueryParam, searchParams]);

  //Open the add affiliate modal if the user is eligible

  //assumed that this will get called after the router has processes the query.
  useEffect(() => {
    //If they have an affiliate code, open the affiliate modal
    if (userData && !affiliateRedeemed.current) {
      affiliateRedeemed.current = true;

      const affiliate = StorageService.getReferralCode();
      if (userData?.affiliate?.eligible && affiliate) {
        router.push(`/?modal=wallet&tab=affiliate`);
      } else {
        StorageService.removeReferralCode();
      }
    }

    const localPromo = StorageService.getLocalStorageValue("promo");

    //Send the promo code if the user is logged in
    if (userData && localPromo && !refPromoSent.current) {
      refPromoSent.current = true;

      NODE_API2.post(
        "v1/promotions/redeem-code",
        {
          promotion_code: localPromo,
        },
        addTokenHeader()
      )
        .then((res) => {
          if (res.data.success) {
            setTimeout(() => {
              mutate();
            }, 500);
          } else {
            setMessage({ code: "responses." + res.data.error, type: 0 });
          }
        })
        .catch((e) => {
          const msg = e?.response?.data?.error || "er_network";
          setMessage({ code: "responses." + msg, type: 0 });
        })
        .finally(() => {
          StorageService.clearLocalStorageValue("promo");
        });
    }
  }, [addTokenHeader, mutate, router, setMessage, userData]);

  useEffect(() => {
    if (pathname.includes("sportsbook-rules")) {
      setMenuState("games");
    } else if (pathname.includes("sports")) {
      setMenuState("sports");
    } else {
      setMenuState("games");
    }
  }, [pathname]);

  // Set miniSide state from localStorage, runs once and doesnt run on sports
  useEffect(() => {
    const savedMiniSideState = localStorage.getItem("miniSideState");
    if (savedMiniSideState === null) return;

    setMiniSide(JSON.parse(savedMiniSideState));
  }, [setMiniSide]);

  const updateMiniSidePreference = (newState) => {
    localStorage.setItem("miniSideState", !newState ? "true" : "false");
  };

  const openChatMobile = useCallback(() => {
    setMenuToggle(false);
    setChat((item) => !item);
  }, [setChat, setMenuToggle]);

  const openMenuMobile = useCallback(() => {
    setChat(false);
    setMenuToggle((item) => !item);
  }, [setChat, setMenuToggle]);

  const { data: userDetail, mutate: refetchUserDetails } = useUserDetails();

  const rakebackBoost = userDetail?.rakeback_boost;

  const [rakebackBoosted, setRakebackBoosted] =
    useRecoilState(userRakebackBoosted);

  const { isBoostExpired, boostProgress } = useMemo(() => {
    const diffExpire = rakebackBoost?.expires_at
      ? differenceInMilliseconds(rakebackBoost?.expires_at, now)
      : 0;

    const diffTotal =
      rakebackBoost?.expires_at && rakebackBoost?.started_at
        ? differenceInMilliseconds(
            rakebackBoost?.expires_at,
            rakebackBoost?.started_at
          )
        : 0;

    const boostProgress = (diffExpire / diffTotal) * 100;

    const isBoostExpired = diffExpire < 1;

    if (isBoostExpired) {
      return { boostProgress: 0, isBoostExpired: true };
    }

    return { boostProgress, isBoostExpired };
  }, [now, rakebackBoost]);

  const isRakeBoostActive = rakebackBoost && !isBoostExpired;

  const lastExpiredBoostRef = useRef(null);

  useEffect(() => {
    if (isBoostExpired) {
      refetchUserDetails();
    }
  }, [isBoostExpired, refetchUserDetails]);

  useEffect(() => {
    if (isBoostExpired && rakebackBoost?.id !== lastExpiredBoostRef.current) {
      lastExpiredBoostRef.current = rakebackBoost?.id;

      refetchUserDetails();

      const checkInterval = setInterval(() => {
        refetchUserDetails();
      }, 30000);

      return () => clearInterval(checkInterval);
    }
  }, [isBoostExpired, rakebackBoost?.id, refetchUserDetails]);

  useEffect(() => {
    if (rakebackBoosted) {
      refetchUserDetails().then(() => {
        setRakebackBoosted(false);
      });
    }
  }, [rakebackBoosted, refetchUserDetails, setRakebackBoosted]);

  const rewardsData = {
    daily: {
      claimable: "2024-12-15 00:00:00",
    },
    weekly: {
      claimable: "2024-12-16 00:00:00",
    },
    monthly: {
      claimable: "2025-01-01 00:00:00",
    },
    rakeback: {
      amount: "0.00",
      sufficient_funds: false,
      claimable: "2024-12-13 20:18:18",
    },
    calendar: {
      amount: 0,
      claimable: "2024-12-14 00:00:00",
      sufficient_funds: false,
    },
    rewards_available: 0,
  };

  console.log(getTimeRemaining(now, rewardsData["monthly"].claimable));
  console.log(getTimeRemaining(now, rewardsData["rakeback"].claimable));
  console.log(getTimeRemaining(now, rewardsData["calendar"].claimable));

  return (
    <div
      className={`${st.container} ${userData ? st["logged-in"] : ""} ${
        chat ? st["active-chat"] : ""
      }`}
    >
      {userData && (
        <div className={st["mobile-navbar-container"]} data-nav="mobile">
          <div className={st["left-side"]}>
            {isMobile && (
              <Link href="/">
                <NewLogoIcon height={25} width={25} />
              </Link>
            )}

            <div className={st["balance"]}>
              <BalanceDropdown />
              <Button
                buttonType={"link"}
                isShallow
                url={{
                  pathname,
                  query: { modal: "wallet", tab: "deposit" },
                }}
                icon={
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.74524 4.22852C1.5 4.70982 1.5 5.33988 1.5 6.6V11.4C1.5 12.6601 1.5 13.2902 1.74524 13.7715C1.96095 14.1948 2.30516 14.5391 2.72852 14.7548C3.20982 15 3.83988 15 5.1 15H12.9C14.1601 15 14.7902 15 15.2715 14.7548C15.6948 14.5391 16.039 14.1948 16.2548 13.7715C16.5 13.2902 16.5 12.6601 16.5 11.4C16.5 11.3172 16.4328 11.25 16.35 11.25H12.75C11.5074 11.25 10.5 10.2426 10.5 9C10.5 7.75736 11.5074 6.75 12.75 6.75H16.35C16.4328 6.75 16.5 6.68284 16.5 6.6C16.5 5.33988 16.5 4.70982 16.2548 4.22852C16.039 3.80516 15.6948 3.46095 15.2715 3.24524C14.7902 3 14.1601 3 12.9 3H5.1C3.83988 3 3.20982 3 2.72852 3.24524C2.30516 3.46095 1.96095 3.80516 1.74524 4.22852Z"
                      fill="white"
                    />
                    <path
                      d="M13.5 9C13.5 9.41421 13.1642 9.75 12.75 9.75C12.3358 9.75 12 9.41421 12 9C12 8.58579 12.3358 8.25 12.75 8.25C13.1642 8.25 13.5 8.58579 13.5 9Z"
                      fill="white"
                    />
                  </svg>
                }
                classType={["green", "mobile-deposit"]}
                style={{ marginLeft: "5px" }}
              />
            </div>
          </div>

          <div className={`${st["right-side"]}`}>
            <div className={st["rewards-container"]}>
              <RewardsDropdown
                isRakeBoostActive={isRakeBoostActive}
                isMobile={isMobile}
                boostProgress={boostProgress}
              />
            </div>

            <Button
              shallow
              classType={[
                "header-search",
                "square",
                "mobile-nav",
                "header-search-btn",
              ]}
              icon={<SearchIcon />}
              buttonType={"link"}
              isShallow
              url={{
                pathname,
                query: { modal: "search" },
              }}
            />
          </div>
        </div>
      )}

      <div className={st["nav"]}>
        <div className={st["content-align-container"]}>
          <div className={st["menu-toggle"]}>
            <Button
              classType={["icon-only"]}
              icon={
                <svg
                  width="18"
                  height="13"
                  viewBox="0 0 18 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.78491 12.1468H8.35257C8.87076 12.1468 9.29082 11.7268 9.29082 11.2086C9.29082 10.6904 8.87076 10.2704 8.35257 10.2704H1.78491C1.26672 10.2704 0.846663 10.6904 0.846663 11.2086C0.846663 11.7268 1.26672 12.1468 1.78491 12.1468ZM1.78491 7.09982H16.7966C17.3148 7.09982 17.7349 6.67973 17.7349 6.16158C17.7349 5.64339 17.3148 5.22333 16.7966 5.22333H1.78487C1.26669 5.22333 0.846628 5.64339 0.846628 6.16158C0.846628 6.67973 1.26672 7.09982 1.78491 7.09982ZM1.78491 2.05276H16.7966C17.3148 2.05276 17.7349 1.6327 17.7349 1.11452C17.7349 0.596329 17.3148 0.17627 16.7966 0.17627H1.78487C1.26669 0.17627 0.846628 0.596329 0.846628 1.11452C0.846628 1.6327 1.26672 2.05276 1.78491 2.05276Z"
                    fill="white"
                  />
                </svg>
              }
              method={() => {
                setMiniSide((item) => {
                  updateMiniSidePreference(item);
                  return !item;
                });
              }}
            />
          </div>

          <div className={st["logo-container"]}>
            <div className={st["header-button-container"]}>
              <div className={st["logo-content"]}>
                <Link
                  href={{
                    pathname: "/",
                  }}
                >
                  <XmasLogo className={st["logo-content-web"]} />
                  <NewLogoIcon
                    height={25}
                    width={25}
                    className={st["logo-content-mobile"]}
                  />
                </Link>
              </div>
            </div>
            <div className={st["section-nav-desktop"]}>
              <Button
                url={{
                  pathname: "/casino/",
                }}
                buttonType={"link"}
                classType={["nav-section-toggle"]}
                text={L("casino", {})}
                icon={<CasinoIconNew />}
                activeItem={menuState === "games"}
              />

              <Button
                url={{
                  pathname: "/sportsbook",
                }}
                buttonType={"link"}
                classType={["nav-section-toggle"]}
                text={L("sports", {})}
                icon={"sports"}
                activeItem={
                  pathname.includes("sports") &&
                  !pathname.includes("sportsbook-rules")
                }
              />
            </div>
          </div>
        </div>

        {userData && (
          <div className={st["center-balance"]}>
            <div className={st["balance"]}>
              <div className={st["mini-search"]}>
                <Button
                  classType={["header-search", "square"]}
                  icon={<SearchIcon />}
                  buttonType={"link"}
                  isShallow
                  url={{
                    pathname,
                    query: { modal: "search" },
                  }}
                />
              </div>
              <div className={st["rewards-container-desktop"]}>
                <RewardsDropdown
                  isRakeBoostActive={isRakeBoostActive}
                  isMobile={isMobile}
                  boostProgress={boostProgress}
                />
              </div>

              <BalanceDropdown />

              <Button
                buttonType={"link"}
                query={{ modal: "wallet", tab: "deposit" }}
                isShallow
                active={false}
                text={L("wallet", {})}
                classType={["wallet-button", "deposit", "header-btn"]}
              />

              <Button
                buttonType={"link"}
                isShallow
                query={{ modal: "wallet", tab: "deposit" }}
                icon={
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.74524 4.22852C1.5 4.70982 1.5 5.33988 1.5 6.6V11.4C1.5 12.6601 1.5 13.2902 1.74524 13.7715C1.96095 14.1948 2.30516 14.5391 2.72852 14.7548C3.20982 15 3.83988 15 5.1 15H12.9C14.1601 15 14.7902 15 15.2715 14.7548C15.6948 14.5391 16.039 14.1948 16.2548 13.7715C16.5 13.2902 16.5 12.6601 16.5 11.4C16.5 11.3172 16.4328 11.25 16.35 11.25H12.75C11.5074 11.25 10.5 10.2426 10.5 9C10.5 7.75736 11.5074 6.75 12.75 6.75H16.35C16.4328 6.75 16.5 6.68284 16.5 6.6C16.5 5.33988 16.5 4.70982 16.2548 4.22852C16.039 3.80516 15.6948 3.46095 15.2715 3.24524C14.7902 3 14.1601 3 12.9 3H5.1C3.83988 3 3.20982 3 2.72852 3.24524C2.30516 3.46095 1.96095 3.80516 1.74524 4.22852Z"
                      fill="white"
                    />
                    <path
                      d="M13.5 9C13.5 9.41421 13.1642 9.75 12.75 9.75C12.3358 9.75 12 9.41421 12 9C12 8.58579 12.3358 8.25 12.75 8.25C13.1642 8.25 13.5 8.58579 13.5 9Z"
                      fill="white"
                    />
                  </svg>
                }
                classType={["green", "mobile-deposit"]}
              />
              <div className={st["notification-mobile"]}>
                {isMobile && <NotificationDropdown mobile socket={socket} />}
              </div>
            </div>
          </div>
        )}

        <div className={st["nav-content-right"]}>
          {!userData ? (
            <>
              <div className={st["auth-section"]}>
                <div className={st["mini-search"]}>
                  <Button
                    classType={["header-search", "square"]}
                    icon={<SearchIcon />}
                    buttonType={"link"}
                    isShallow
                    url={{
                      pathname,
                      query: { modal: "search" },
                    }}
                  />
                </div>
                <div className={st["rewards-container"]}>
                  <RewardsDropdown
                    isRakeBoostActive={isRakeBoostActive}
                    isMobile={isMobile}
                    boostProgress={boostProgress}
                  />
                </div>
                <Button
                  buttonType={"link"}
                  query={{ modal: "auth", tab: "login" }}
                  active={false}
                  isShallow
                  text={L("login", {})}
                  classType={["header-btn", "grey-button", "login-button"]}
                />

                <Button
                  buttonType={"link"}
                  query={{ modal: "auth", tab: "register" }}
                  active={false}
                  isShallow
                  text={!isMobile ? L("register_now", {}) : L("register", {})}
                  classType={["header-btn", "register-button"]}
                />

                {!isMobile ? (
                  <div className={st["chat-space"]}>
                    <Button
                      icon={<ChatIco />}
                      method={handleChat}
                      param={(item) => {
                        return !item;
                      }}
                      activeItem={chat}
                      classType={["messenger", "square"]}
                    />
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            <>
              <div className={st["user-right-side"]}>
                <div className={st["mini-search"]}>
                  <Button
                    classType={["header-search", "square"]}
                    icon={<SearchIcon />}
                    buttonType={"link"}
                    isShallow
                    url={{
                      pathname,
                      query: { modal: "search" },
                    }}
                  />
                </div>
                {isRakeBoostActive && (
                  <RakebackBoost
                    rakebackRate={rakebackBoost?.rakeback_rate}
                    boostType={rakebackBoost?.type}
                    progress={boostProgress}
                    leftTime={timeLeft(now, rakebackBoost?.expires_at)}
                    strokeWidth={1.5}
                    noHover={activeRewardsDropdown}
                    size={25}
                  />
                )}
                <div className={st["rewards-container"]}>
                  <RewardsDropdown
                    isRakeBoostActive={isRakeBoostActive}
                    isMobile={isMobile}
                    boostProgress={boostProgress}
                  />
                </div>

                <div className={st["chat-notification"]}>
                  {!isMobile && (
                    <NotificationDropdown mobile={false} socket={socket} />
                  )}

                  <ProfileDropdown />

                  <Button
                    icon={<ChatIco />}
                    method={handleChat}
                    activeItem={chat}
                    param={(item) => {
                      return !item;
                    }}
                    classType={["messenger", "square"]}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <MobileNav setMenuToggle={openMenuMobile} setChat={openChatMobile} />
    </div>
  );
}
