function CalendarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      fill="none"
      viewBox="0 0 35 34"
    >
      <path
        fill="url(#paint0_linear_12853_100545)"
        d="M35 20.951c0-3.469-4.546-6.141-10.77-6.48V6.52C24.23 2.015 18.145 0 12.116 0S.002 2.015 0 6.52v19.21c0 4.502 6.085 6.519 12.115 6.519.623 0 1.257-.056 1.898-.117a19.396 19.396 0 008.872 1.86c6.029 0 12.115-2.017 12.115-6.52v-6.521zm-2.692 0c0 1.845-4.03 3.907-9.423 3.907-5.394 0-9.424-2.063-9.424-3.907.07-.425.242-.83.504-1.177a2.78 2.78 0 011-.826.97.97 0 00.089-.06 15.38 15.38 0 017.83-1.84c5.394 0 9.424 2.063 9.424 3.907v-.004zm-10.77-8.264c-.065.4-.224.778-.464 1.108-.241.33-.557.602-.923.794a16.09 16.09 0 00-6.271 1.917c-.573.047-1.153.082-1.765.082-5.076 0-9.423-2.148-9.423-3.905v-1.826a18.415 18.415 0 009.423 2.184 18.415 18.415 0 009.423-2.184v1.83zM2.692 16.733a17.629 17.629 0 008.506 2.452 4.022 4.022 0 00-.429 1.766v2.106c-4.473-.333-8.077-2.249-8.077-3.849v-2.475zm9.423-14.118c4.633 0 9.423 1.46 9.423 3.906 0 2.537-4.846 3.905-9.423 3.905S2.692 9.058 2.692 6.52c0-2.445 4.79-3.906 9.423-3.906zM2.692 25.729v-2.477a17.385 17.385 0 008.077 2.427v1.794a4.465 4.465 0 00.539 2.145c-4.364-.158-8.616-1.586-8.616-3.889zm20.193 5.648a16.742 16.742 0 01-7.68-1.607 2.907 2.907 0 01-1.19-.92 2.79 2.79 0 01-.552-1.378v-2.325a17.564 17.564 0 009.422 2.329 17.564 17.564 0 009.423-2.329v2.326c0 2.443-4.79 3.904-9.423 3.904z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_12853_100545"
          x1="17.5"
          x2="17.5"
          y1="34"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#33A9FF"></stop>
          <stop offset="1" stopColor="#6FFA8E"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default CalendarIcon;
