import st from "./raffle-drop-down.module.scss";
import { useAtomValue } from "jotai";
import MiniTicketIcon from "../../assets/mini-ticket.png";
import Image from "next/image";
import { useDict } from "@/hooks/useLanguage";
import Link from "next/link";
import SmallTicketIcon from "../../assets/small-ticket-icon.jsx";
import MiniBannerBackground from "../../assets/mini-banner-background.png";
import useSWR from "swr";
import NodeAPI from "@/lib/api/nodeApi";
import { userRaffleDataAtom } from "@Games/Raffle/jotai-raffle";
import useRaffleConvert from "@Games/Raffle/helpers/use-raffle-convert";

export default function RaffleItemDropdown() {
  const userRaffleData = useAtomValue(userRaffleDataAtom);
  const dict = useDict("raffle");
  const convertRaffleValue = useRaffleConvert();

  const { data: userTicketsData } = useSWR("/v1/raffles/my-tickets", (url) =>
    NodeAPI.get(url).then((res) => res?.data)
  );

  return (
    <Link href="/raffle" className={st["raffle-drop-down-container"]}>
      <div className={st["left-side"]}>
        <div className={st["icon-container"]}>
          <Image
            src={MiniTicketIcon}
            width={45}
            height={"auto"}
            alt="mini-ticket-icon"
          />
        </div>
        <div className={st["heading-container"]}>
          <span className={st["title"]}>{convertRaffleValue(50000)}</span>
          {dict({
            name: "weekly_raffle",
            as: "span",
            className: st["sub-title"],
          })}
        </div>
      </div>
      <div className={st["right-side"]}>
        <SmallTicketIcon />
        <span>
          {userTicketsData?.tickets?.length || userRaffleData?.ticketCount}
        </span>
      </div>
      <div className={st["background-image-container"]}>
        <Image src={MiniBannerBackground} alt="mini-banner-background" />
      </div>
    </Link>
  );
}
