import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@components/new-ui/popover";
import Image from "next/image";
import RakebackProgress from "@components/RakebackBoost/RakebackProgress";
import {
  RewardsIconBackground,
  RewardsIconBase,
} from "@assets/svgs/RewardsIcon";
import { useEffect, useMemo, useState } from "react";
import {
  RewardsMontlyRewardsIcon,
  RewardsWeeklyIcon,
} from "@assets/svgs/RewardsIcons";
import CalendarIcon from "@assets/svgs/CalendarIcon";
import {
  RewardsCalendarDailyIcon,
  RewardsGiftIcon,
} from "@assets/svgs/RewardsIcons";
import useSWR from "swr";
import API from "@lib/api/api";
import { differenceInMilliseconds } from "date-fns";
import { removeDateOffset } from "@lib";
import { timeLeft } from "@lib/timer";
import useNow from "@hooks/useNow";
import RakebackBoost from "@components/RakebackBoost";
import { useUserDetails, useUserSession } from "@hooks/useUserSession";
import RewardsDropdownContent from "./rewards-dropdown-content";
import { useSetAtom } from "jotai";
import { activeRewardsDropdownAtom } from "@store/rewards-dropdown-atom";

interface RewardsDropdownProps {
  isRakeBoostActive: boolean;
  boostProgress: number;
  isMobile: string | boolean;
}

export const CHESTS = [
  { image: CalendarIcon, name: "daily_calendar", api: "calendar" },
  { image: RewardsGiftIcon, name: "rakeback", api: "rakeback" },
  { image: RewardsCalendarDailyIcon, name: "daily_bonus", api: "daily" },
  { image: RewardsWeeklyIcon, name: "weekly_bonus", api: "weekly" },
  { image: RewardsMontlyRewardsIcon, name: "monthly_bonus", api: "monthly" },
];

export function RewardsDropdown({
  isRakeBoostActive,
  boostProgress,
  isMobile,
}: RewardsDropdownProps) {
  const { hasToken } = useUserSession();
  const now = useNow();
  const { data: userDetail } = useUserDetails();
  const setActiveDropdown = useSetAtom(activeRewardsDropdownAtom);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsOpen(false);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const rakebackBoost = userDetail?.rakeback_boost;

  const { data: rewardsData, mutate: getRewardsData } = useSWR(
    hasToken ? "user/rewards" : null,
    () => API.get("user/rewards").then((res) => res.data),
    {
      keepPreviousData: true,
      refreshInterval: 300000,
      revalidateOnFocus: false,
    }
  );
  const rewardsAvailable = rewardsData?.rewards_available || 0;

  const nextAvailableRewardTime = useMemo(
    () =>
      Object.values(rewardsData || {})
        .map((a: any) => a.claimable)
        .filter((a) => a)
        .map((a) => {
          return differenceInMilliseconds(removeDateOffset(a), now);
        })
        .sort()
        .filter((a) => a > 0)?.[0] || null,
    [now, rewardsData]
  );

  useEffect(() => {
    if (nextAvailableRewardTime) {
      const timeout = setTimeout(getRewardsData, nextAvailableRewardTime);
      return () => clearTimeout(timeout);
    }
  }, [nextAvailableRewardTime, getRewardsData]);

  return (
    <Popover
      open={isOpen}
      onOpenChange={() => {
        setIsOpen((prev) => !prev);
        setActiveDropdown((prev) => !prev);
      }}
    >
      <PopoverTrigger className="w-[42px] h-[42px] flex items-center justify-center border border-[#363c5e] rounded-md bg-gradient-to-b from-[rgba(124,131,177,0.15)] to-[rgba(94,103,158,0.12)] hover:bg-[#222846] hover:border-[1.2px] hover:border-[#7d83a8] hover:shadow-[inset_0_0_2px_0_#7d83a8] hover:[background:linear-gradient(#222846,#222846)_padding-box,linear-gradient(90deg,#6b7198,#656c96)_border-box] hover:[border-image:linear-gradient(180deg,#6e759d,#515a89)_1] transition-all duration-300 ease">
        {isMobile === "mobile-two" && isRakeBoostActive ? (
          <RakebackProgress
            progress={boostProgress}
            size={25}
            strokeWidth={1.5}
          />
        ) : (
          <div className="relative transition-all duration-300 ease z-[99]">
            <div>
              <RewardsIconBase />
            </div>

            <div className="hidden opacity-0 transition-all duration-300 ease">
              <RewardsIconBackground />
              <Image
                src="/Gifs/gift_box.gif"
                width={40}
                height={40}
                alt="Rewards"
              />
            </div>
          </div>
        )}

        {rewardsAvailable >= 1 && (
          <span
            className="absolute -top-2 -right-2 min-w-[18px] h-[18px] flex items-center justify-center text-xs rounded-full border border-solid border-[rgba(255,255,255,0.5)] bg-[#20a83e] text-white shadow-[0px_0px_12px_0px_rgba(0,119,219,0.5),0px_0px_6px_0px_rgba(0,0,0,0.3)] transition-all duration-300 ease"
            style={{
              background: "linear-gradient(0deg, #1aa338 0%, #20a83e 100%)",
              textShadow: "0px 0px 8px rgba(0,0,0,0.55)",
            }}
          >
            {rewardsAvailable}
          </span>
        )}
      </PopoverTrigger>
      <PopoverContent align="end">
        <RewardsDropdownContent
          rewardsData={rewardsData}
          getRewardsData={getRewardsData}
          rakebackBoost={
            isRakeBoostActive && (
              <RakebackBoost
                mobile={true}
                rakebackRate={rakebackBoost?.rakeback_rate}
                boostType={rakebackBoost?.type}
                progress={boostProgress}
                leftTime={timeLeft(now, rakebackBoost?.expires_at)}
                size={42}
              />
            )
          }
        />
      </PopoverContent>
    </Popover>
  );
}
