import Button from "../../items/Button";
import API, { NODE_API } from "../../lib/api/api";
import usePopup from "../../hooks/usePopup";
import { useState, useEffect, useCallback } from "react";
import { handleCatchPopup } from "@lib/tools/helpers";
import LoadingSpinner from "@components/Loading/LoadingSpinner";
import useNow from "@hooks/useNow";
import { isAfter } from "date-fns";
import { useSetRecoilState } from "recoil";
import userRakebackBoosted from "@recoil/rakebackBoosted/atom";
import { CHESTS } from "./rewards-dropdown";
import ValueDisplay from "@items/ValueDisplay";
import { useUserSession } from "@hooks/useUserSession";
import useLanguage from "@hooks/useLanguage";
import RaffleItemDropdown from "@Games/Raffle/components/raffle-drop-down";
import { getTimeRemaining } from "@lib";

function RewardContentRow({
  countdown,
  refetch,
  typeOf,
  hasToken,
  ImageComponent,
  name,
  hasSufficientFunds,
  hasExpired,
  amount,
}) {
  const canClaim =
    typeOf === "rakeback" || typeOf === "calendar"
      ? hasSufficientFunds && hasExpired
      : hasExpired;

  const setMessage = usePopup();
  const L = useLanguage(["meta", "Rewards", "header"]);
  const { mutate, addTokenHeader } = useUserSession();
  const [isLoading, setIsLoading] = useState(false);
  const setRakebackBoosted = useSetRecoilState(userRakebackBoosted);

  const collect = useCallback(
    async (type) => {
      if (!hasToken) return;

      setIsLoading(true);

      try {
        const api = type === "calendar" ? API : NODE_API;
        const endpoint =
          type === "calendar"
            ? "rewards/calendar-claim"
            : "v1/rewards/chest-claim";
        const payload =
          type === "calendar"
            ? { date: new Date().toISOString().split("T")[0] }
            : { type };

        const res = await api.post(endpoint, payload, addTokenHeader());
        await Promise.all([mutate(), refetch()]);

        if (!res?.data?.success) throw res.data;

        const data = res.data;

        setRakebackBoosted(true);
        setMessage({
          type: 1,
          raw: true,
          code: data.currency_calendar_reward
            ? "responses.su_reward_chest"
            : "responses.su_reward_chest_no_calendar",
          replacements: {
            TOKEN_AMOUNT: data.currency_amount_redeemed,
            ...(data.currency_calendar_reward && {
              CALENDAR_REWARD: data.currency_calendar_reward,
            }),
            ...(data.currency_instant_reward && {
              INSTANT_REWARD: data.currency_instant_reward,
            }),
            ...(data.currency && {
              CURRENCY: data.currency,
            }),
          },
        });
      } catch (error) {
        handleCatchPopup(setMessage)(error);
        await Promise.all([mutate(), refetch()]);
      } finally {
        setIsLoading(false);
      }
    },
    [hasToken, addTokenHeader, mutate, refetch, setRakebackBoosted, setMessage]
  );

  const shouldCount =
    hasSufficientFunds || (typeOf !== "rakeback" && typeOf !== "calendar")
      ? countdown
      : L("claim", {});

  const btnClaimText = canClaim
    ? L("claim", {})
    : shouldCount || L("claim", {});

  const text = isLoading ? <LoadingSpinner type="button" /> : btnClaimText;

  return (
    <div className="flex items-center gap-4 justify-between rounded-md border border-[#3d446c] bg-gradient-to-b from-[rgba(124,131,177,0.15)] to-[rgba(94,103,158,0.15)] px-4 py-[10px] pr-3">
      <div className="flex items-center gap-3">
        <div className="w-10">
          <ImageComponent
            width={64}
            height={64}
            alt="gift"
            style={{ width: "100%", height: "auto", objectFit: "contain" }}
          />
        </div>
        <div className="flex flex-col gap-[5px]">
          <span className="text-[13px] break-normal">{L(name, {})}</span>
          {hasToken && (typeOf === "rakeback" || typeOf === "calendar") && (
            <ValueDisplay
              greyIconOnEmpty
              amount={amount}
              size={"small"}
              classType={["custom-value-display"]}
            />
          )}
        </div>
      </div>

      {hasToken ? (
        <Button
          text={text}
          style={{
            height: 34,
          }}
          classType={
            canClaim
              ? ["claim-dropdown"]
              : countdown
                ? ["claim-dropdown", "timer"]
                : ["claim-dropdown", "disabled"]
          }
          method={() => collect(typeOf)}
          deActive={!canClaim}
        />
      ) : (
        <Button
          buttonType={"link"}
          query={{ modal: "auth", tab: "login" }}
          active={false}
          text={L(" log_in_claim", {})}
          classType={["blue", "claim-dropdown"]}
          style={{
            padding: "8px 10px",
          }}
        />
      )}
    </div>
  );
}

export default function RewardsDropdownContent({
  rewardsData,
  getRewardsData,
  rakebackBoost,
}) {
  const { hasToken } = useUserSession();
  const L = useLanguage(["header"]);
  const now = useNow();

  const countdowns = !rewardsData
    ? {}
    : CHESTS.reduce(
        (acc, chest) => ({
          ...acc,
          [chest.api]: getTimeRemaining(now, rewardsData[chest.api].claimable),
        }),
        {}
      );

  useEffect(() => {
    getRewardsData();
  }, [getRewardsData]);

  return (
    <div
      data-rewards-dropdown
      className="relative flex flex-col gap-2 shadow-[0_2px_5px_0_rgba(0,0,0,0.15),0_1px_1px_0_rgba(0,0,0,0.05)] rounded-md border border-solid border-white/10 bg-[#262c52] p-2 max-w-[330px]"
    >
      <div className="flex flex-col gap-2">
        <RaffleItemDropdown />
        {rakebackBoost}
        {CHESTS.map((chest) => {
          const data = rewardsData?.[chest.api];

          return (
            <RewardContentRow
              key={chest.api}
              refetch={getRewardsData}
              typeOf={chest.api}
              countdown={countdowns[chest.api] || ""}
              hasToken={hasToken}
              ImageComponent={chest.image}
              name={chest.name}
              hasSufficientFunds={!!data?.sufficient_funds}
              hasExpired={isAfter(now, data?.claimable)}
              amount={data?.amount}
            />
          );
        })}
      </div>

      <div className="h-[1px] w-full bg-white/10 my-[10px]"></div>

      <div>
        <Button
          buttonType={"link"}
          url={{ pathname: "/rewards/" }}
          text={L("all_rewards", {})}
          classType={["reward-dropdown", "all-rewards", "full-width"]}
        />
      </div>
    </div>
  );
}
