import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@components/new-ui/popover";
import { SvgUnranked } from "@components/Svg/SvgStore";
import { Dict } from "@hooks/useLanguage";
import { useRankProgress } from "@hooks/useRankProgress";
import { logout } from "@hooks/useUserSession";
import RewardIcons from "@items/RewardIcons";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { DropdownIcons } from "@assets/icons/dropdown";
import { ReactNode, useEffect, useState } from "react";
import { cn } from "@lib";

const menuItems = [
  {
    text: "profile",
    icon: <DropdownIcons.ProfileDropdownIcon />,
    href: { modal: "profile", tab: "overview" },
    isShallow: true,
  },
  {
    text: "bets",
    icon: <DropdownIcons.BetsDropdownIcon />,
    href: "/bets",
  },
  {
    text: "transactions",
    icon: <DropdownIcons.TransactionsDropdownIcon />,
    href: "/transactions",
  },
  {
    text: "affilitates",
    icon: <DropdownIcons.AffiliatesDropdownIcon />,
    href: "/affiliates",
  },
  {
    text: "verification",
    icon: <DropdownIcons.VerificationDropdownIcon />,
    href: "/settings",
  },
  {
    text: "vault",
    icon: <DropdownIcons.VaultDropdownIcon />,
    href: { modal: "vault" },
    isShallow: true,
  },
  {
    text: "rewards",
    icon: <DropdownIcons.RewardsDropdownIcon />,
    href: "/rewards",
  },
  {
    text: "settings",
    icon: <DropdownIcons.SettingsDropdownIcon />,
    href: "/settings",
    isShallow: true,
  },
];

export default function ProfileDropdown({ trigger }: { trigger?: ReactNode }) {
  const pathname = usePathname();
  const { userRank, progress, isLoading } = useRankProgress();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsOpen(false);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger
        className={cn(
          trigger
            ? "flex items-center justify-center"
            : "h-[42px] pl-[10px] pr-[14px] flex items-center justify-center border border-[#363c5e] rounded-md bg-gradient-to-b from-[rgba(124,131,177,0.15)] to-[rgba(94,103,158,0.12)]"
        )}
      >
        {trigger ? (
          trigger
        ) : (
          <div className="flex justify-center gap-2">
            <div className="w-[25px]">
              {userRank?.name ? (
                <RewardIcons code={userRank?.name} size="xlarge" />
              ) : (
                <SvgUnranked />
              )}
            </div>
            <div className="flex gap-1 flex-col justify-center relative">
              <div className="flex items-center gap-2">
                <Dict
                  section="common"
                  name="account"
                  as="span"
                  className="font-medium text-[12px] text-[#e8e5ff]"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 18 18"
                  fill="none"
                  className={`transition-transform duration-200 ${isOpen ? "rotate-180" : ""}`}
                >
                  <path
                    d="M8.99961 12.6008C8.87046 12.6008 8.74551 12.5767 8.62476 12.5286C8.50336 12.4806 8.4023 12.4164 8.32158 12.3363L3.86598 7.91185C3.6884 7.73551 3.59961 7.51109 3.59961 7.23857C3.59961 6.96605 3.6884 6.74162 3.86598 6.56528C4.04356 6.38895 4.26956 6.30078 4.544 6.30078C4.81844 6.30078 5.04445 6.38895 5.22203 6.56528L8.99961 10.3164L12.7772 6.56528C12.9548 6.38895 13.1808 6.30078 13.4552 6.30078C13.7297 6.30078 13.9557 6.38895 14.1332 6.56528C14.3108 6.74162 14.3996 6.96605 14.3996 7.23857C14.3996 7.51109 14.3108 7.73551 14.1332 7.91185L9.67764 12.3363C9.58077 12.4325 9.47584 12.5004 9.36284 12.5402C9.24983 12.5806 9.12876 12.6008 8.99961 12.6008Z"
                    fill="#525984"
                  ></path>
                </svg>
              </div>

              <div className="relative h-[3px]">
                {!isLoading ? (
                  <div className="absolute w-full h-[3px] bg-[#1E2134] rounded-[4px] overflow-hidden">
                    <div
                      className="h-full w-[33%] bg-[#0077DB]"
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </PopoverTrigger>
      <PopoverContent
        align="end"
        className="flex flex-col gap-2 shadow-[0_2px_5px_0_rgba(0,0,0,0.15),0_1px_1px_0_rgba(0,0,0,0.05)] rounded-md border border-solid border-white/10 bg-[#262c52] p-2 max-w-[330px]"
      >
        {menuItems.map((item) => {
          const href =
            typeof item.href === "object"
              ? { href: { pathname, query: { ...item.href } } }
              : { href: item.href };

          return (
            <Link
              key={item.text}
              {...href}
              shallow={item.isShallow}
              onClick={handleClose}
            >
              <button className="flex items-center px-[10px] h-[32px] text-[13px] w-full border-0 gap-[10px] hover:bg-[#0077b8] rounded-[6px] transition-[background-color] duration-200 ease-in-out">
                {item.icon}
                <Dict name={item.text} as="p" section="header" />
              </button>
            </Link>
          );
        })}

        <button
          onClick={() => {
            handleClose();
            logout();
          }}
          className="flex items-center px-[10px] h-[32px] text-[13px] w-full border-0 gap-[10px] hover:bg-[#0077b8] rounded-[6px] transition-[background-color] duration-200 ease-in-out"
        >
          <DropdownIcons.LogoutDropdownIcon />
          <Dict
            name="logout"
            as="span"
            section="common"
            className="font-medium"
          />
        </button>
      </PopoverContent>
    </Popover>
  );
}
