function WalletSettingsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="15"
      fill="none"
      viewBox="0 0 17 15"
    >
      <path
        stroke="#7179A5"
        strokeMiterlimit="10"
        strokeWidth="1.455"
        d="M13.363 3.35V2.098c0-.69-.559-1.248-1.248-1.248H2.363a1.25 1.25 0 000 2.5h12.252c.69 0 1.248.559 1.248 1.248v1.877"
      ></path>
      <path
        stroke="#7179A5"
        strokeMiterlimit="10"
        strokeWidth="1.455"
        d="M15.863 10.225V12.1c0 .69-.559 1.249-1.248 1.249H2.363c-.69 0-1.25-.56-1.25-1.25v-10"
      ></path>
      <path
        stroke="#7179A5"
        strokeMiterlimit="10"
        strokeWidth="1.455"
        d="M15.863 10.225h-3.125a1.875 1.875 0 110-3.75h3.125v3.75z"
      ></path>
    </svg>
  );
}

export default WalletSettingsIcon;
