import React from "react";
import st from "@/styles/components/RakebackBoost.module.scss";

export default function RakebackProgress({
  size = 25,
  strokeWidth = 1.5,
  progress = 0,
}) {
  const p = progress || 100;
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = +circumference - (+p / 100) * +circumference;
  const rotate = (1 - p / 100) * 360 - 90;
  const width = size;
  const height = size + 4;
  const baseSize = 25;
  const scale = size / baseSize;

  return (
    <div className={st["svg-container"]}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
      >
        <g
          filter="url(#filter0_d_17393_131581)"
          transform={`translate(${width / 2 - 12.5 * scale}, ${height / 2 - 12.5 * scale - 4}) scale(${scale})`}
        >
          <path
            fill="url(#paint0_linear_17393_131581)"
            d="m14.6 11.353-.394 8.255a.41.41 0 0 1-.41.392h-2.471c-.22 0-.401-.173-.412-.392l-.393-8.255H8.854a.412.412 0 0 1-.319-.673l3.706-4.529a.412.412 0 0 1 .638 0l3.705 4.53a.412.412 0 0 1-.318.672zm1.665 8.262a.412.412 0 1 1-.822-.053l.412-6.383a.412.412 0 1 1 .822.053zm-6.588-.053a.412.412 0 1 1-.822.053l-.412-6.383a.412.412 0 1 1 .822-.053z"
          />
          <path
            stroke="url(#paint1_linear_17393_131581)"
            strokeWidth="0.4"
            d="M14.218 10.953h2.054zm0 0-.018.38m.018-.38-.018.38m0 0-.393 8.256m.393-8.255-.393 8.255m0 0a.012.012 0 0 1-.012.011m.012-.011-.012.011m.411.008.394-8.255h1.666a.412.412 0 0 0 .318-.673zm0 0a.41.41 0 0 1-.41.392h-2.471c-.22 0-.401-.173-.412-.392z"
          />
        </g>

        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeDasharray={circumference}
          strokeDashoffset={0}
          strokeOpacity={0.2}
          stroke="#fff"
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          stroke={"url(#paint2_linear_16614_35042)"} // Referencing the gradient here
          transform={`rotate(${rotate}, ${size / 2}, ${size / 2})`}
        />
        <defs>
          <linearGradient
            id="paint0_linear_17393_131581"
            x1="12.56"
            x2="12.56"
            y1="6"
            y2="20"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFB300" />
            <stop offset="1" stopColor="#FFB300" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_17393_131581"
            x1="12.56"
            x2="12.56"
            y1="6"
            y2="20"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#fff" />
            <stop offset="1" stopColor="#fff" stopOpacity="0.1" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_16614_35042"
            x1="13.7486"
            y1="1.5275"
            x2="11.1154"
            y2="23.4856"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD166" />
            <stop offset="1" stopColor="#FFB300" />
          </linearGradient>
          <filter
            id="filter0_d_17393_131581"
            width="20.236"
            height="26"
            x="2.441"
            y="3"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="3" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0.112831 0 0 0 0 0.145539 0 0 0 0 0.227311 0 0 0 1 0" />
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_17393_131581"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_17393_131581"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
}

export const svgRakeBoost = () => {
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="29"
    fill="none"
    viewBox="0 0 25 29"
  >
    <g filter="url(#filter0_d_17393_131581)">
      <path
        fill="#000"
        d="m14.6 11.353-.394 8.255a.41.41 0 0 1-.41.392h-2.471c-.22 0-.401-.173-.412-.392l-.393-8.255H8.854a.412.412 0 0 1-.319-.673l3.706-4.529a.412.412 0 0 1 .638 0l3.705 4.53a.412.412 0 0 1-.318.672zm1.665 8.262a.412.412 0 1 1-.822-.053l.412-6.383a.412.412 0 1 1 .822.053zm-6.588-.053a.412.412 0 1 1-.822.053l-.412-6.383a.412.412 0 1 1 .822-.053z"
      ></path>
      <path
        fill="url(#paint0_linear_17393_131581)"
        d="m14.6 11.353-.394 8.255a.41.41 0 0 1-.41.392h-2.471c-.22 0-.401-.173-.412-.392l-.393-8.255H8.854a.412.412 0 0 1-.319-.673l3.706-4.529a.412.412 0 0 1 .638 0l3.705 4.53a.412.412 0 0 1-.318.672zm1.665 8.262a.412.412 0 1 1-.822-.053l.412-6.383a.412.412 0 1 1 .822.053zm-6.588-.053a.412.412 0 1 1-.822.053l-.412-6.383a.412.412 0 1 1 .822-.053z"
      ></path>
      <path
        stroke="url(#paint1_linear_17393_131581)"
        strokeWidth="0.8"
        d="M14.218 10.953h2.054zm0 0-.018.38m.018-.38-.018.38m0 0-.393 8.256m.393-8.255-.393 8.255m0 0a.012.012 0 0 1-.012.011m.012-.011-.012.011m.411.008.394-8.255h1.666a.412.412 0 0 0 .318-.673zm0 0a.41.41 0 0 1-.41.392h-2.471c-.22 0-.401-.173-.412-.392zm2.069-8.674-3.706-4.53.31-.253-.31.253-.004-.003-.005-.001-.005.001q-.001 0-.004.003l-.3-.245.3.245-3.706 4.53-.004.005zm0 0 .003.005zm-5.373.019.018.38.393 8.256zm0 0h-.382m.382 0h-.382m0 0H8.854m1.666 0H8.854m4.941 8.647h-2.47m2.47 0h-2.47m0 0a.012.012 0 0 1-.012-.011zm-2.471-8.647h-.006zm7.012 8.636a.012.012 0 1 1-.024-.002l.412-6.382a.012.012 0 1 1 .023.002zm-6.612 0-.412-6.382q.001-.011.011-.013.011.001.013.011l.411 6.382a.012.012 0 0 1-.01.013.012.012 0 0 1-.013-.011Z"
      ></path>
    </g>
    <path
      stroke="url(#paint2_linear_17393_131581)"
      strokeLinecap="round"
      strokeWidth="1.5"
      d="M23.375 14.282a11.13 11.13 0 0 1-2.479 5.773 11.07 11.07 0 0 1-5.204 3.5A11.02 11.02 0 0 1 4.12 20.332a11.1 11.1 0 0 1-2.657-5.689 11.14 11.14 0 0 1 .85-6.226 11.1 11.1 0 0 1 4.084-4.768 11.04 11.04 0 0 1 6.007-1.78"
    ></path>
    <path
      stroke="#fff"
      strokeOpacity="0.2"
      strokeWidth="1.5"
      d="M6.993 22.654a11.13 11.13 0 0 1-4.379-4.506 11.07 11.07 0 0 1-1.231-6.15 11.02 11.02 0 0 1 7.42-9.446c2.02-.69 4.198-.784 6.272-.268a11.14 11.14 0 0 1 5.423 3.177 11.1 11.1 0 0 1 2.834 5.601c.384 2.1.154 4.263-.665 6.23"
    ></path>
    <defs>
      <linearGradient
        id="paint0_linear_17393_131581"
        x1="12.56"
        x2="12.56"
        y1="6"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB300"></stop>
        <stop offset="1" stopColor="#FFB300"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear_17393_131581"
        x1="12.56"
        x2="12.56"
        y1="6"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0.1"></stop>
      </linearGradient>
      <linearGradient
        id="paint2_linear_17393_131581"
        x1="13.708"
        x2="11.074"
        y1="2.026"
        y2="23.984"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD166"></stop>
        <stop offset="1" stopColor="#FFB300"></stop>
      </linearGradient>
      <filter
        id="filter0_d_17393_131581"
        width="20.236"
        height="26"
        x="2.441"
        y="3"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="3"></feOffset>
        <feGaussianBlur stdDeviation="3"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0.112831 0 0 0 0 0.145539 0 0 0 0 0.227311 0 0 0 1 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_17393_131581"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_17393_131581"
          result="shape"
        ></feBlend>
      </filter>
    </defs>
  </svg>;
};
